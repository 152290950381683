import React from "react"
import { Link } from 'gatsby'

import Layout from "../layouts"
import Head from "../components/head"

import '../css/dietl-international-insurance-summary.css';
import dietlImage from '../images/dietllogo.png';

const DietlInternationalInsuranceSummaryPage = () => (
  <div className="row">
		<div id="container_contact" className="col-sm-12">
      <div id="content_contact" >
        <div className="prof_title" >
          <h1 className="entry-title">Dietl International Insurance Summary</h1>
        </div>

        <div className="prof" style={{ margin: "35px auto 0 auto", textAlign: "center" }}>
          <p><img src={dietlImage} height="100px" alt="image"/></p>

          <a href="https://www.platformart.com/faq "><button className="btn">RETURN TO PLATFORM</button></a>
          <h1>Summary of Fine Arts Insurance</h1>
          <h3>Policy Number RIGCAR07210031 Certain Underwriters at Lloyds 100% MRS 457</h3>
          <p className="left"><i>The information provided below is only a summary* of the full Marine Open Cargo Policy written for the account of Dietl International
          Services which is effective from 09/01/2020---09/01/2021
          Insurance coverage is secured via Roanoke Insurance Group Inc. and provided under policy issued to Dietl International Services,
          by Certain Underwriters at Lloyds MRS 457. All Insurance coverage is subject to the full terms and conditions of the policy.</i>
          </p>
          <p className="left">
          <i>*This summary is provided as a matter of information only and confers no legal or contractual rights upon the recipient. This
          summary does not amend, extend or alter the coverage, terms exclusions and conditions afforded by the policy referenced herein.</i></p>
          <table style={{ borderCollapse: "collapse" }} cellSpacing="0">
            <tbody>
              <tr>
                <td className="titlerow">
                  <p className="s1 center" >General Exclusions (See Policy For Complete Details)</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p><b><u>This policy does not insure against any loss, damage or expense attributable to or caused by: </u></b>A.) Ordinary leakage, ordinary loss in weight or volume, or ordinary wear and tear of the goods insured; B.) Willful misconduct, infidelity, conversion or dishonest acts of the Assured, or the Assured's employees, whether committed alone or in collusion with others; C.) Rust, oxidation or discoloration on unpacked and/or unprotected cargo; D.) Loss of market or loss, damage, expense or deterioration arising from delay, whether caused by a peril insured against or otherwise; E.) Inherent vice or nature of the goods insured; F.) Insolvency or financial default of the owners, managers, charterers, or operators of the vessel where, at the time of loading of the goods insured on board the vessel; G.) Insufficiency or unsuitability of packing or preparation of the goods; H.) Electrical, electronic and/or mechanical derangement unless the Assured is able to demonstrate that such damage is the result of a peril insured against; I.) Shipments on chartered vessels that are not classed A1 American Record or equivalent by a member of the International Association of Classification Societies; chartered vessels over 40 years of age; J.) Any accident occurring while rolling stock cargo is being driven under its own power or being towed on its own wheels.

                  </p>
                  <p><b><u>Geographical Limits</u></b> To and from ports and or places in the World to ports and or places in the World with privilege of transshipment by land and/or water; however, shipments to/from Afghanistan, Iran, Iraq, Libya, Somalia, Syria, Yemen and truck/rail shipments to/from/within Mexico other than as a connecting conveyance are excluded entirely. Also including domestic shipments within the United States and Canada. All other domestic shipments are excluded unless specifically endorsed hereon. Regardless of the Duration of Risk clauses found elsewhere in this Policy, coverage for shipments to Haiti, Nigeria, Pakistan, Ukraine and the Commonwealth of Independent States (CIS) ceases upon discharge from the ocean vessel or aircraft.</p>
                </td>
              </tr>
              <tr>
                <td className="titlerow">
                  <p className="s1 center">Limits of Liability</p>
                </td>
              </tr>
              <tr>
                <td>
                  <table border="1px solid black" className="subtable">
                    <tbody>
                      <tr>
                        <td>$100,000</td>
                        <td>Any one vessel or aircraft or truck or conveyance or location during the ordinary course of transit</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                </td>
              </tr>
              <tr>
                <td className="titlerow">
                  <p className="s1 center">Valuation</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ul id="li" style={{ marginLeft: '30px' }}><li><p>Valued at amount of invoice, including all charges therein, plus any prepaid and/or advanced and/or guaranteed freight, if any, or at amounts declared prior to shipment. In the absence of an invoice, valued as agreed between Customers and Dietl International Services. However, in no case shall the value exceed professional appraisal value. ii. In the event of partial loss of or damage to any item insured the amount payable will be the cost and expense of restoration plus any resulting depreciation but not exceeding the full value of that item, valued as noted herein.</p></li></ul>
                </td>
              </tr>
              <tr>

                <td className="titlerow">
                  <p className="s1 center">Average Terms and Conditions</p>
                </td>
              </tr>
              <tr >
                <td>
                  <p className="center"><b><u>Insured against “All Risks” of physical loss or damage from any external cause except as excluded by the Clauses in Section 7 of the policy.</u></b></p>
                  <ul id="li" style={{ marginLeft: '30px' }}>
                    <li><p>The Assured must ensure that the insured property is professionally packed for transit.
                    </p></li>

                    <li><p>
                    Subject to the following Pairs and Sets Clause: – “In the event of loss and/or damage to any article(s) comprising part of a pair or set claims hereunder are to take into account any depreciation in value of such pair and/or set”.
                    ”</p></li>

                    <li><p>Subject to the following Partial Loss Clause: – “In the event of partial loss or damage to any item insured hereon the amount of loss shall be the cost and expense of restoration plus any resulting depreciation in value. Underwriters’ liability shall be limited to that proportion of such loss or damage which the sum insured bears to the market value of the item immediately prior to the loss and in no event shall Underwriters be liable for more than the insured value of the item.”</p></li>

                  </ul>
                </td>
              </tr>
              <tr>
                <td className="titlerow">
                  <p className="s1 center">Sanctions Limitation &amp; Exclusion Clause</p>
                </td>
              </tr>
                <tr>
                <td>
                  <p>Sanctions Limitation &amp; Exclusion Clause No (re)insurer shall be deemed to provide cover and no (re)insurer shall be liable to pay any claim or provide any benefit hereunder to the extent that the provision of such cover, payment of such claim or provision of such benefit would expose that (re)insurer to any sanction, prohibition or restriction under United Nations resolutions or the trade or economic sanctions, laws or regulations of the European Union, United Kingdom or United States of America.
                  </p>
                </td>
                </tr>
              <tr>
                <td className="titlerow">
                  <a href="https://infospot.roanokegroup.com/acton/attachment/5777/f-8bd61868-d29a-47a3-9c25-fc73636466e1/1/-/-/-/-/Dietl%20Intl%20Lloyds%20DS%20Policy.pdf" target="_new">
                    <p className="s1 center">Click Here for a copy of the full policy</p>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <br />

          <a href="https://www.platformart.com/faq "><button className="btn">RETURN TO PLATFORM</button></a>

        </div>
      </div>
	  </div>
  </div>
)

export default DietlInternationalInsuranceSummaryPage
